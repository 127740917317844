<template>
  <div>
    <div id="OperationsSimulation" v-if="$route.name === 'SimulationsOperations'">
      <div class="giga-content_container">
      <div class="giga-actions">
        <div class="giga-actions_left">
          <ul class="giga-actions_row">
            <li class="giga-action_item">
              <div class="giga-action_search">
                <input type="search" name="" id="" placeholder="Recherche" v-model="search" @input="operationSearch()"/>
                <button class="giga-search_button">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.7448 22.6899L17.772 16.7171C21.2724 12.4326 20.6421 6.12211 16.3576 2.62172C12.0768 -0.878671 5.76261 -0.248376 2.26222 4.03613C-1.23817 8.32064 -0.607873 14.6311 3.67663 18.1315C7.36461 21.1479 12.6696 21.1479 16.3576 18.1315L22.3304 24.1043C22.7431 24.472 23.3771 24.4382 23.7448 24.0255C24.0862 23.6466 24.0862 23.0726 23.7448 22.6899ZM10.0321 18.3941C5.60879 18.3941 2.02586 14.8149 2.02211 10.3916C2.02211 5.96829 5.60129 2.38536 10.0246 2.38161C14.4479 2.38161 18.0309 5.96078 18.0346 10.3841C18.0346 10.3841 18.0346 10.3879 18.0346 10.3916C18.0271 14.8074 14.4479 18.3866 10.0321 18.3941Z"
                      fill="#8C9DAB"
                    />
                  </svg>
                </button>
              </div>
              <!-- <div></div> -->
            </li>
            <filter-modal
              :query-config="queryConfig"
              v-on:onFilter="applyFilter($event)"
              v-on:onReloadData="applyFilter($event)"
              :headerTitle="'filtrer_operation'"
              :currentFilter="currentFilter"
            ></filter-modal>
          </ul>
        </div>
        <div class="giga-actions_right">
          <ul class="giga-actions_row">
            <li class="giga-action_item">
              <div class="giga-button">
                <button class="giga-green_button with-icon" @click="download()">
                  <i class="giga-icon">
                    <b-spinner v-if="downloadLoader" variant="light" small class="spinner"></b-spinner>
                  </i>
                  <i v-if="!downloadLoader" class="giga-icon">
                    <svg
                      class="stroke"
                      width="20"
                      height="17"
                      viewBox="0 0 20 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.2386 10.7527V14.0036C18.2386 14.8999 17.3821 15.6267 16.3258 15.6267H2.91284C1.85648 15.6267 0.999969 14.8999 0.999969 14.0036V10.7527"
                        stroke="white"
                        stroke-width="1.69271"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4.83139 6.68774L9.61642 10.7526L14.4072 6.68774"
                        stroke="white"
                        stroke-width="1.69271"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.61639 10.7528V1"
                        stroke="white"
                        stroke-width="1.69271"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </i>
                  <span>Télécharger</span>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
        <page-loader v-if="loading"></page-loader>
        <table
            v-else
          class="giga-table"
          :aria-describedby="$t('operations_list')"
        >
          <thead>
            <tr>
              <th
                @click="sortTable('institution.siteName')"
                class="sort"
                scope="col"
                :class="sortBy === 'institution.siteName' ? sortDir : ''"
              >
                {{ $t("operation_name") }}
              </th>
              <th
                @click="sortTable('beginDate')"
                class="sort"
                scope="col"
                :class="sortBy === 'beginDate' ? sortDir : ''"
              >
                {{ $t("commitment_date") }}
              </th>
              <th
                @click="sortTable('endDate')"
                class="sort"
                scope="col"
                :class="sortBy === 'endDate' ? sortDir : ''"
              >
                {{ $t("completion_date") }}
              </th>
              <th
                @click="sortTable('operationVolume.precarityVolume')"
                class="sort"
                scope="col"
                :class="
                  sortBy === 'operationVolume.precarityVolume' ? sortDir : ''
                "
              >
                {{ $t("precarious_volume") }}
              </th>
              <th
                @click="sortTable('operationVolume.classicVolume')"
                class="sort"
                scope="col"
                :class="
                  sortBy === 'operationVolume.classicVolume' ? sortDir : ''
                "
              >
                {{ $t("classic_volume") }}
              </th>
              <th
                @click="sortTable('ficheCalcul.fiche.number')"
                class="sort"
                scope="col"
                :class="sortBy === 'ficheCalcul.fiche.number' ? sortDir : ''"
              >
                {{ $t("plug_cee") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="operation in getOperations.content">
                <tr
                  class="cursor-pointer"
                  @click="onRowSelected(operation.id)"
                  :key="operation.id"
                >
                  <td><p>{{ operation.siteName }}</p></td>
                  <td>
                    <p> {{ operation.beginDate ? moment(operation.beginDate, "x").format("DD/MM/YYYY") : "" }} </p>
                  </td>
                  <td>
                    <p> {{ operation.endDate ? moment(operation.endDate, "x").format("DD/MM/YYYY") : "" }} </p>
                  </td>
                  <td>
                    <p>{{ operation.precarityVolume | formatNumber }}<span> kWh cumac</span></p>
                  </td>
                  <td>
                    <p>{{ operation.classicVolume | formatNumber }}<span> kWh cumac</span></p>
                  </td>
                  <td>
                    <p>{{ operation.ficheNumber }}</p>
                  </td>
                </tr>
            </template>
          </tbody>
        </table>

    <pagination
      v-if="rows >= perPage"
      :length="length"
      :rows="rows"
      :perPage="perPage"
      :currentPage="currentPage"
      v-on:changePerPage="changePerPage($event)"
      v-on:changeCurrentPage="changeCurrentPage($event)"
    ></pagination>
      </div>
  </div>
    <router-view v-else/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Pagination from '@/components/static/Pagination.vue';
import FilterModal from "@/components/static/FilterModal.vue";
import pageLoader from '../../components/static/pageLoader.vue'
import PageLoader from '../../components/static/pageLoader.vue'
import {queryConstant} from "@/shared/queryConstant";
import {workService} from "../Works/services";
import {debounce} from "lodash"

export default {
  components: { Pagination, FilterModal, pageLoader, PageLoader },
  name: "OperationsSimulation",
  data() {
    const _pageSize_10 = 10;
    const _pageSize_25 = 25;
    const _pageSize_50 = 50;
    return {
      scrollBarSettings: {
        scrollXMarginOffset: 50,
      },
      perPage: 10,
      currentPage: 1,
      length: [_pageSize_10, _pageSize_25, _pageSize_50],
      sortBy: null,
      sortDir: "asc",
      customFilter: queryConstant.setDefaultCustomFilter(),
      currentFilter: {},
      queryConfig: queryConstant.SIMULATION_OPERATION_CONFIG,
      search: '',
      fetchData: () => {},
    };
  },
  mounted() {
    this.getWorkOperations();
    this.fetchData = debounce(this.getWorkOperations, 500);
  },
  computed: {
    ...mapGetters("simulation", ["getOperations"]),
    ...mapGetters("loader", {loading: "getLoading"}),
    ...mapGetters("loader", { downloadLoader: "getDownloadOperations" }),
    rows() {
      return this.getOperations.totalElements;
    },
  },
  methods: {
    ...mapActions("simulation", ["getSimulationOperationsAction"]),
    getWorkOperations() {
      this.getSimulationOperationsAction({
        id: parseInt(this.$route.params["id"], 10),
        currentPage: this.currentPage,
        perPage: this.perPage,
        sortBy: this.sortBy,
        sortDir: this.sortDir,
        customFilter: this.customFilter,
        search: this.search
      });
    },
    changePerPage(perPage) {
      this.currentPage = 1;
      this.perPage = perPage;
      this.getWorkOperations();
    },
    changeCurrentPage(currentPage) {
      this.currentPage = currentPage;
      this.getWorkOperations();
    },
    sortTable(name) {
      if (name === this.sortBy) {
        if (this.sortDir === "asc") {
          this.sortDir = "desc";
        } else {
          this.sortDir = "asc";
        }
      }
      this.sortBy = name;
      this.getWorkOperations();
    },
    onRowSelected(idOperation) {
      this.$router.push({
        name: "SimulationsOperation",
        params: {
          id: parseInt(this.$route.params["id"], 10),
          idOperation: idOperation,
        },
      });
    },
    applyFilter(filter) {
      this.customFilter = filter.custom;
      this.currentFilter = filter.filters;
      this.currentPage = 1;
      this.getWorkOperations();
    },
    download() {
      workService.downloadOperations(
        this.customFilter,
        parseInt(this.$route.params["idLot"], 10),
      );
    },
    operationSearch() {
      this.fetchData();
    }
  },
};
</script>
